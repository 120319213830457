const loginUrl = "https://assessment.hiri.ai/login";
export const RedirectToLogin = () => {
  const returnUrl = SetReturnUrl();
  const redirectUrl = `${loginUrl}{returnUrl}`;
  if (window.location.href.include(loginUrl)) {
  } else {
    window.location.replace(redirectUrl);
  }
};
export const GetReturnUrl = () => {
  const url = new URL(window.location.href);
  const returnUrl = url.searchParams.get("returnUrl");
  return returnUrl == null ? null : decodeURIComponent(returnUrl);
};

export const SetReturnUrl = () => {
  const existingReturnUrl = GetReturnUrl();
  const returnUrl =
    existingReturnUrl ?? encodeURIComponent(window.location.href);
  // https://assessment.hiri.ai/login

  console.log(existingReturnUrl, returnUrl);
  if (returnUrl) {
    return `/login?returnUrl=${encodeURIComponent(returnUrl)}`;
  }
  return "/login";
};
