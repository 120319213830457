import React, { useEffect, useState } from "react";
function AudioCountdown({ initialTime }) {
  const hours = Math.floor(initialTime / 3600);
  const minutes = Math.floor((initialTime % 3600) / 60);
  const seconds = initialTime % 60;
  const displayHours = hours > 0 ? hours.toString().padStart(2, "0") : "";
  const displayMinutes = minutes.toString().padStart(2, "0");
  const displaySeconds = seconds.toString().padStart(2, "0");
  return (
    <div style={{ display: "flex" }}>
      {hours === 0 ? null : (
        <>
          <div> {displayHours}</div>
          <div style={{ width: "auto" }}>:</div>
        </>
      )}
      <div> {displayMinutes}</div>
      <div style={{ width: "auto" }}>:</div>
      <div> {displaySeconds}</div>
    </div>
  );
}
export default AudioCountdown;
