import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DropdownBasicWebcam from "../../components/DropdownBasicWebcam";
import { changeExamStep } from "../../app/examActions/action";
import nextIcon from "../../assets/svg/icons/NEXTARROW.svg";
import cam from "../../assets/svg/icons/CAM_ICON.svg";
import mic from "../../assets/svg/icons/MIC_ICON.svg";
import rec from "../../assets/svg/icons/REC_ICON.svg";
import play from "../../assets/svg/icons/PLAY_ICON.svg";
import { AnimatePresence } from "framer-motion";
import { AlertModal } from "../../components/modals/Alert";
import { toggleAlertModal } from "../../components/modals/modalActions";
const Step3 = () => {
  const dispatch = useDispatch();
  const [recording, setRecording] = useState(false);
  const [recordedBlob, setRecordedBlob] = useState(null);
  const videoRef = useRef(null);
  const recordRef = useRef(null);
  const streamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const [audioDevices, setAudioDevices] = useState([]);
  async function getDevices() {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      setVideoDevices(devices.filter((device) => device.kind === "videoinput"));
      setAudioDevices(devices.filter((device) => device.kind === "audioinput"));
    } catch (error) {
      console.warn("Kullanıcı izin vermedi veya hata oluştu", error);
    }
  }
  useEffect(() => {
    getDevices();
  }, []);
  const startCam = async () => {
    setNext(true);
    const constraints = {
      video: {
        deviceId: { exact: selectedVideoDevice.deviceId },
      },
    };
    streamRef.current = await navigator.mediaDevices.getUserMedia(constraints);
    videoRef.current.srcObject = streamRef.current;
  };
  useEffect(() => {
    if (selectedVideoDevice || selectedAudioDevice) startCam();
  }, [selectedVideoDevice, selectedAudioDevice]);

  const handleStartRecording = async () => {
    setRecordedBlob(null);
    startCam();
    try {
      const constraints = {
        video: {
          deviceId: { exact: selectedVideoDevice.deviceId },
          facingMode: "user",
        },
        audio: {
          deviceId: { exact: selectedAudioDevice.deviceId },
          sampleRate: 48000,
          echoCancellation: false,
        },
      };
      streamRef.current = await navigator.mediaDevices.getUserMedia(
        constraints
      );
      mediaRecorderRef.current = new MediaRecorder(streamRef.current);
      // mediaRecorderRef.current.volume = 5.0; // Sabit ses düzeyi
      mediaRecorderRef.current.start();
      const chunks = [];
      mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
        chunks.push(event.data);
      });
      mediaRecorderRef.current.addEventListener("stop", () => {
        const recordedBlob = new Blob(chunks, {
          type: mediaRecorderRef.current.mimeType,
        });
        setRecordedBlob(URL.createObjectURL(recordedBlob));
      });
      setRecording(true);
    } catch (error) {
      console.error(error);
      dispatch(toggleAlertModal(true));
    }
  };
  const handleStopRecording = () => {
    mediaRecorderRef.current.stop();
    streamRef.current.getTracks().forEach((track) => track.stop());
    setRecording(false);
  };
  const [next, setNext] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const { alertModal } = useSelector((store) => store.modals);
  useEffect(() => {
    if (!alertModal) document.body.style.overflow = "auto";
  }, [alertModal]);
  return (
    <>
      <AnimatePresence initial={false}>
        {alertModal && <AlertModal />}
      </AnimatePresence>
      <div
        className="step step-3 webcam"
        style={
          alertModal
            ? { display: "none" }
            : { display: "flex", overflow: "auto" }
        }
      >
        <div className="title">
          <div>Teste başlamaya hazır olduğunuzdan emin olalım!</div>
          <span>Bu test sesli ve görüntülü sorular içermektedir.</span>
        </div>
        <div className="config-box">
          <div className="left">
            <div className="title">
              Kamera ve Mikrofon Kurulumu
              <span />
            </div>
            <div className="rules-bottom">
              <div className="rules">
                <div>
                  1. Sağ üst kısımda bulunan mikrofon ve kamera seçim menüsünden
                  cihaz seçimi yapın.
                </div>
                <div>
                  2. Tarayıcınızda “Kamera ve Mikrofon Erişimi”ne dair bir uyarı
                  çıkacaktır. “İzin Ver”e tıklayın. Böylece sesli veya video ile
                  yanıtlanan soruları tamamlayabileceksiniz.
                </div>
                <div>
                  3. <span>Deneme Kaydı Başlat</span> butonuna tıklayın ve
                  konuşmaya başlayın. Video kaydını tamamlamak için{" "}
                  <span>Kaydı Tamamla</span> butonuna tıklayın.
                </div>
                <div>
                  4. <span>Kaydı Oynat</span> butonuna tıklayın ve kaydınızda
                  ses ve görüntünün sorunsuz olduğundan emin olun.
                </div>
              </div>{" "}
              <div className="info">
                Hiri’nin güvenlikli test altyapısında adayların kamera
                görüntüleri test süresince kaydedilmektedir. Kamera ve
                mikrofonunuzu kullanarak soruları bir video ya da ses kaydı ile
                yanıtlamanız istenecektir. Bu sebeple kameranızın ve
                mikrofonunuzun düzgün çalıştığından emin olmalısınız. Bu adımda
                kaydedeceğiniz deneme videoları saklanmaz ve kurumlara
                gösterilmez.
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="right">
            <div className="source-select-box">
              <DropdownBasicWebcam
                icon={mic}
                placeHolder="Mikrofon Seç"
                selected={selectedAudioDevice}
                setSelected={setSelectedAudioDevice}
                options={audioDevices}
              />
              <DropdownBasicWebcam
                icon={cam}
                placeHolder="Kamera Seç"
                selected={selectedVideoDevice}
                setSelected={setSelectedVideoDevice}
                options={videoDevices}
              />
            </div>
            <div className="cam-area">
              {recordedBlob === null && (
                <video ref={videoRef} autoPlay playsInline controls={false} />
              )}
              {recordedBlob ? (
                <video
                  src={recordedBlob}
                  ref={recordRef}
                  controls={false}
                  playsInline
                />
              ) : null}
            </div>
            <div className="buttons">
              {!recording ? (
                <div
                  style={
                    isPlaying
                      ? { opacity: 0.2, cursor: "default" }
                      : { opacity: 1 }
                  }
                  className="record"
                  onClick={() => {
                    if (isPlaying) return;
                    handleStartRecording();
                  }}
                >
                  <img src={rec} alt="icon" /> Deneme Kaydı Başlat
                </div>
              ) : (
                <div className="record" onClick={handleStopRecording}>
                  <img src={rec} alt="icon" /> Deneme Kaydını Durdur
                </div>
              )}
              {recording}

              {recordedBlob ? (
                <div
                  className={isPlaying | recording ? "play disabled" : "play"}
                  disabled={recording}
                  onClick={(e) => {
                    if (recording) return;
                    setNext(false);
                    setIsPlaying(true);
                    recordRef.current.volume = 1;
                    recordRef.current.play();
                    recordRef.current.onended = () => {
                      setIsPlaying(false);
                    };
                  }}
                >
                  <img src={play} alt="icon" />
                  {isPlaying ? "Oynatılıyor" : "Kaydı Oynat"}
                </div>
              ) : (
                <div className="play disabled">
                  <img src={play} alt="icon" />
                  Kaydı Oynat
                </div>
              )}
            </div>
          </div>
        </div>
        <button
          disabled={next}
          className="next-button-step transform-3"
          onClick={(e) => {
            if (selectedVideoDevice === null || selectedAudioDevice === null)
              return;
            dispatch(changeExamStep("fakeInfo"));
            window.scroll(0, 0);
          }}
        >
          İleri <img src={nextIcon} alt="icon" />
        </button>
      </div>
    </>
  );
};

export default Step3;
