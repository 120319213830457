import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modals",
  initialState: {
    kvkkModal: false,
    exitExam: false,
    currentExamData: null,
    alertModal: false,
  },
  reducers: {
    toggleKvkkModal: (state, action) => {
      state.kvkkModal = action.payload;
    },
    toggleAlertModal: (state, action) => {
      state.alertModal = action.payload;
    },
    toggleExitExam: (state, action) => {
      state.exitExam = action.payload.isOpen;
      if (action.payload.data !== null) {
        state.currentExamData = action.payload.data;
      }
    },
  },
});
export const { toggleKvkkModal, toggleExitExam, toggleAlertModal } =
  modalSlice.actions;
export default modalSlice.reducer;
