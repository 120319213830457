import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeExamStep } from "../../app/examActions/action";
import nextIcon from "../../assets/svg/icons/NEXTARROW.svg";
const RealExamInfo = () => {
  const dispatch = useDispatch();
  const { activeExamData } = useSelector((store) => store.assessments);
  return (
    <div className="step step-1" style={{ justifyContent: "center" }}>
      <div className="sub-title">
        <div style={{ fontWeight: "500" }}>
          Örnek soruları tamamladınız! Şimdi testi başlatma zamanı!
        </div>
        <div>
          <span style={{ color: "#473AEB", fontWeight: "500" }}>
            {activeExamData.exam.questions.length}
          </span>{" "}
          soruyu cevaplamak için{" "}
          <span style={{ color: "#473AEB", fontWeight: "500" }}>
            {activeExamData.exam.duration}
          </span>{" "}
          dakikanız var.
        </div>
        <div>Tüm sorulara cevap vererek testi bitirin.</div>
        <div className="wish" style={{ color: "#473AEB", fontWeight: "500" }}>
          Hazır olduğunuzda aşağıdaki butona tıklayın!
        </div>
      </div>
      <div
        className="next-button-step transform-3"
        onClick={(e) => {
          dispatch(changeExamStep(1));
        }}
      >
        Başla!
      </div>
    </div>
  );
};

export default RealExamInfo;
