import React, { useEffect, useRef } from "react";
import logo from "../assets/svg/LOGO_LOGIN.svg";
import email from "../assets/svg/icons/EMAIL_ICON.svg";
import password from "../assets/svg/icons/PASS_ICON.svg";
import right from "../assets/svg/BG.svg";
import { useDispatch } from "react-redux";
import { login } from "../app/auth/actions";
import { GetReturnUrl } from "../utils/RedirectHelper";
const Login = ({ toUrl }) => {
  const returnUrl = toUrl ?? GetReturnUrl();
  const dispatch = useDispatch();
  const emailRef = useRef("");
  const passwordRef = useRef("");
  const loginApi = (e, data) => {
    e.preventDefault();
    dispatch(login(data))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        console.log(originalPromiseResult);
        // let x = GetReturnUrl();
        // console.log("unwrap", x);
        window.location.replace(returnUrl);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert(rejectedValueOrSerializedError.message);
        console.log(rejectedValueOrSerializedError.message);
        clearInputs();
      });
  };
  const clearInputs = () => {
    emailRef.current.value = "";
    passwordRef.current.value = "";
  };
  return (
    <>
      <div className="hiri-login-wrapper">
        <div className="left">
          <div className="area">
            <div className="logo">
              <img src={logo} alt="logo" className="responsive-img" />
            </div>
            <form
              onSubmit={(e) => {
                let formData = {
                  email: emailRef.current.value,
                  password: passwordRef.current.value,
                };
                loginApi(e, formData);
              }}
            >
              <div className="input-group">
                <label htmlFor="email">
                  <img src={email} alt="icon" className="responsive-img" />
                </label>
                <input
                  autoComplete="off"
                  type="email"
                  ref={emailRef}
                  onChange={(e) => {
                    emailRef.current.value = e.target.value;
                  }}
                  placeholder="E-Mail veya Kullanıcı Adı"
                  id="email"
                  required
                />
              </div>
              <div className="input-group">
                <label htmlFor="parola">
                  <img src={password} alt="icon" className="responsive-img" />
                </label>
                <input
                  autoComplete="off"
                  type="password"
                  ref={passwordRef}
                  onChange={(e) => {
                    passwordRef.current.value = e.target.value;
                  }}
                  placeholder="Parola"
                  id="parola"
                  required
                />
              </div>
              <div className="button-area">
                {/* <div className="forgot">
                  <span className="pointer">Şifremi Unuttum</span>
                </div> */}
                <br />
                <input className="button" type="submit" value={"GİRİŞ YAP"} />
                {/* <div className="sign-up">
                  Hesabın yok mu?
                  <span
                    className="pointer"
                    onClick={(e) => {
                      navigate("/register");
                    }}
                  >
                    Üye Ol
                    <div className="line" />
                  </span>
                </div> */}
              </div>
            </form>
            {/* <div className="other">
              <div className="text">Veya bu hesaplarından biri ile bağlan</div>
              <div className="social">
                <div className="icon">
                  <img src={google} alt="icon" />
                </div>
                <div className="icon">
                  <img src={linkedin} alt="icon" />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="right">
          <div className="area">
            <div className="textBox">
              <div className="text fade-3">
                <div className="inner">AI-Powered Automated Assessments</div>
              </div>
              <div className="text fade">
                <div className="inner">AI-Powered Automated Assessments</div>
              </div>
              <div className="text">
                <div className="inner">AI-Powered Automated Assessments</div>
              </div>
              <div className="text fade">
                <div className="inner">AI-Powered Automated Assessments</div>
              </div>
              <div className="text fade-3">
                <div className="inner">AI-Powered Automated Assessments</div>
              </div>
            </div>
            <img src={right} alt="bg" className="responsive-img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
