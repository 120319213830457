import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";
import { useSelector } from "react-redux";
import { GetJWT } from "../../utils/CookieHelper";
import { AuthInstance } from "../api/authInstances";
import { toggleLoading } from "../auth/actions";
export const getExam = createAsyncThunk("getExam", async (data, api) => {
  try {
    const response = await AuthInstance.get(
      `${env.API_URL}/items/assessment/${data.assessment_id}?fields=is_screen_record,question_per_page,min_answers,is_questions_shuffle,date_updated,company.*.*, exam.duration, exam.category, exam.questions.*.*.*.*&deep[exam][questions][_limit]=-1&deep[exam][questions][_sort]=order&deep[exam][questions][options][_sort]=order`,
      {
        headers: {
          Authorization: GetJWT().accessToken,
        },
      }
    );
    api.dispatch(toggleLoading(false));
    return response.data.data;
  } catch (error) {
    console.log(error);
    api.dispatch(toggleLoading(false));
    throw error;
  }
});
export const updateStatus = createAsyncThunk(
  "updateStatus",
  async (data, api) => {
    let config;
    if (data.duration !== undefined) {
      config = {
        status: data.status,
        duration: data.duration,
        last_page: data.last_page,
        spent_time: data.spent_time,
        left_time: data.left_time,
        device: data.device,
      };
    } else {
      config = {
        status: data.status,
        last_page: data.last_page,
      };
    }
    try {
      const response = await AuthInstance.patch(
        `${env.API_URL}/items/assessment_profile/${data.id}`,
        config,
        {
          headers: {
            Authorization: GetJWT().accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const answerQuestion = createAsyncThunk(
  "answerQuestion",
  async (data, api) => {
    try {
      const response = await axios.post(
        `${env.API_URL}/items/answer`,
        {
          candidate: data.candidate,
          assessment: data.assessment,
          question_id: data.question_id,
          option_id: data.option_id,
          duration: data.duration,
          content: "This content is need by writing question response.",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: GetJWT().accessToken,
          },
        }
      );
    } catch (error) {
      console.log(error);
      window.location.reload();

      throw error;
    }
  }
);
export const getAssessmentList = createAsyncThunk(
  "getAssessmentList",
  async (data, api) => {
    api.dispatch(toggleLoading(true));
    try {
      const response = await AuthInstance.get(
        `${env.API_URL}/items/assessment_profile?filter[status][_in]=paused,pending&filter[expire_date][_gte]=2023-03-31 12:00`,
        {
          headers: {
            Authorization: GetJWT().accessToken,
          },
        }
      );
      api.dispatch(toggleLoading(false));
      return response.data.data;
    } catch (error) {
      console.log(error);

      api.dispatch(toggleLoading(false));

      throw error;
    }
  }
);
export const getAnswers = createAsyncThunk("getAnswers", async (data, api) => {
  api.dispatch(toggleLoading(true));
  try {
    const response = await AuthInstance.get(
      `${env.API_URL}/items/answer/?filter[assessment][_eq]=${data.assessment_id}`,
      {
        headers: {
          Authorization: GetJWT().accessToken,
        },
      }
    );
    api.dispatch(toggleLoading(false));
    return response.data.data;
  } catch (error) {
    console.log(error);
    api.dispatch(toggleLoading(false));
    throw error;
  }
});
export const getAssessmentDetail = createAsyncThunk(
  "getAssessmentDetail",
  async (assessmentId, api) => {
    api.dispatch(toggleLoading(true));
    try {
      const response = await axios.get(
        `${env.API_URL}/items/assessment/${assessmentId}?fields=id,expire_date,is_screen_record,is_questions_shuffle,min_answers,exam.duration,exam.title,exam.description_rich,company.*.*&sort=expire_date`,
        {
          headers: {
            Authorization: GetJWT().accessToken,
          },
        }
      );
      api.dispatch(toggleLoading(false));
      return response.data.data;
    } catch (error) {
      console.log(error);
      api.dispatch(toggleLoading(false));
      throw error;
    }
  }
);
export const getHomeTableData = createAsyncThunk(
  "getHomeTableData",
  async (data, api) => {
    api.dispatch(toggleLoading(true));
    try {
      const response = await axios.get(
        `${env.API_URL}/items/assessment_profile?filter[status][_in]=completed&sort=-completed_date`,
        {
          headers: {
            Authorization: GetJWT().accessToken,
          },
        }
      );

      api.dispatch(toggleLoading(false));
      return response.data.data;
    } catch (error) {
      console.log(error);
      api.dispatch(toggleLoading(false));
      throw error;
    }
  }
);
const assessmentSlice = createSlice({
  name: "assessments",
  initialState: {
    activeExamData: [],
    activeConfig: [],
    activeAssessmentList: [],
    homepageTableData: [],
    activeAnswerList: [],
    mixedData: [],
    uniqueMixKey: 5,
  },
  reducers: {
    uniqueMixKey: (state, action) => {
      state.uniqueMixKey = action.payload;
    },
    resetExamData: (state, action) => {
      state.activeExamData = [];
      state.activeConfig = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExam.fulfilled, (state, action) => {
      state.activeExamData = action.payload;
      // function shuffle(array, seed) {
      //   var m = array.length,
      //     t,
      //     i;
      //   while (m) {
      //     i = Math.floor(random(seed) * m--);
      //     t = array[m];
      //     array[m] = array[i];
      //     array[i] = t;
      //     ++seed;
      //   }
      //   return array;
      // }
      // function random(seed) {
      //   var x = Math.sin(seed++) * 10000;
      //   return x - Math.floor(x);
      // }
      // soruları mixle
      // if (action.payload.is_questions_shuffle) {
      //   state.mixedData = shuffle(
      //     action.payload.exam.questions,
      //     state.uniqueMixKey
      //   );
      // }
    });
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      state.activeConfig = action.payload.data;
    });
    // get homepage assessment list data
    builder.addCase(getAssessmentList.fulfilled, (state, action) => {
      state.activeAssessmentList = action.payload;
    });
    // get homepage table list data
    builder.addCase(getHomeTableData.fulfilled, (state, action) => {
      state.homepageTableData = action.payload;
    });

    builder.addCase(getAnswers.fulfilled, (state, action) => {
      state.activeAnswerList = action.payload;
    });
  },
});
export default assessmentSlice.reducer;
export const { uniqueMixKey, resetExamData } = assessmentSlice.actions;
