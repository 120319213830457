import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import logo from "../assets/svg/LOGO_LOGIN.svg";
export default function Loader() {
  return (
    <div className="loader_backdrop">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <div className="elements">
          <img src={logo} alt="logo" className="responsive-img" />
          <div className="dots">
            <div className="anim"></div>
            <div className="anim"></div>
            <div className="anim"></div>
            <div className="anim"></div>
          </div>
        </div>
      </Backdrop>
    </div>
  );
}
