import { motion } from "framer-motion";
import { toggleExitExam } from "./modalActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { updateStatus } from "../../app/assessments/actions";
import { toggleLoading } from "../../app/auth/actions";
import { useSelector } from "react-redux";
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "Spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    transition: { duration: 0.6, type: "Spring", damping: 25, stiffness: 500 },
  },
};
export const ExitExam = ({ handleClose, text, onClick }) => {
  const config = useSelector((state) => state.modals);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
  }, []);
  const dispatch = useDispatch();
  const accept = () => {
    dispatch(toggleLoading(true));
    document.body.style.overflow = "";
    dispatch(
      updateStatus({
        id: config.currentExamData.id,
        status: config.currentExamData.status,
        last_page: config.currentExamData.last_page,
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(toggleLoading(false));
        dispatch(
          toggleExitExam({
            isOpen: false,
            data: null,
          })
        );
        window.location.pathname = "/";
        localStorage.setItem("hotfix", "");
      })
      .catch((rejectedValueOrSerializedError) => {
        dispatch(toggleLoading(false));
        window.location.pathname = "/";
      });
  };
  const close = () => {
    document.body.style.overflow = "";
    dispatch(
      updateStatus({
        id: config.currentExamData.id,
        status: "inprogress",
      })
    )
      .unwrap()
      .then((originalPromiseResult) => {
        dispatch(toggleLoading(false));
        dispatch(
          toggleExitExam({
            isOpen: false,
            data: null,
          })
        );
        localStorage.setItem("hotfix", "");
      })
      .catch((rejectedValueOrSerializedError) => {
        dispatch(toggleLoading(false));
        window.location.pathname = "/";
      });
  };

  return (
    <motion.div
      className="backdrop2"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modal_data"
        style={{ height: "fit-content" }}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="title">Sınavdan çıkmak istediğinize emin misiniz?</div>
        <div>
          <span
            onClick={(e) => {
              accept();
            }}
          >
            Evet
          </span>
          <span
            onClick={(e) => {
              close();
            }}
          >
            Hayır
          </span>
        </div>
        <div
          className="closeButton"
          onClick={(e) => {
            close();
          }}
        >
          X
        </div>
      </motion.div>
    </motion.div>
  );
};
