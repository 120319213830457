import React, { useEffect, useState } from "react";
import Timeline from "../../components/Timeline";
import next from "../../assets/svg/icons/NEXTARROW.svg";
import { changeExamStep } from "../../app/examActions/action";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import fakeQuestion from "../../assets/images/generaltest-fake.png";
import AudioReverse from "../../components/TimerComponents/AudioReverseComponent";
import play from "../../assets/svg/play-icon.svg";
import { useRef } from "react";
export const FakeExam = () => {
  const dispatch = useDispatch();
  const [controller, setController] = useState(1);
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);
    return matches;
  };
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  const { activeExamData } = useSelector((store) => store.assessments);
  const fakeDataPersonal = [
    {
      order: 1,
      question_id: {
        content_rich:
          "<p>İnsanların zayıflıkları ve eksiklikleri gelişimleri i&ccedil;in birer hediyedir.</p>",
        duration: null,
        is_mandatory: false,
        wait_duration: null,
        answer_duration: null,
        id: "fb5e9421-dca4-4233-9944-95ca3450e8da",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p>Hi&ccedil; Katılmıyorum</p>",
              id: 11,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>Katılmıyorum</p>",
              id: 12,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>Fikrim Yok</p>",
              id: 13,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>Katılıyorum</p>",
              id: 14,
            },
          },
          {
            order: 5,
            option_id: {
              content_rich: "<p>Tamamen Katılıyorum</p>",
              id: 15,
            },
          },
        ],
      },
    },
    {
      order: 2,
      question_id: {
        content_rich: "<p>Keşke dediğim &ccedil;ok olur.</p>",
        duration: null,
        is_mandatory: false,
        wait_duration: null,
        answer_duration: null,
        id: "91ac583a-37aa-4e3c-abba-34561485ef97",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p>Hi&ccedil; Katılmıyorum</p>",
              id: 16,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>Katılmıyorum</p>",
              id: 17,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>Fikrim Yok</p>",
              id: 18,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>Katılıyorum</p>",
              id: 19,
            },
          },
          {
            order: 5,
            option_id: {
              content_rich: "<p>Tamamen Katılıyorum</p>",
              id: 20,
            },
          },
        ],
      },
    },
    {
      order: 3,
      question_id: {
        content_rich: "<p>Kendimi su&ccedil;ladığım &ccedil;ok olur.</p>",
        duration: null,
        is_mandatory: false,
        wait_duration: null,
        answer_duration: null,
        id: "b41cccef-a48c-4e89-9c8b-823b1086ac9d",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p>Hi&ccedil; Katılmıyorum</p>",
              id: 16,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>Katılmıyorum</p>",
              id: 17,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>Fikrim Yok</p>",
              id: 18,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>Katılıyorum</p>",
              id: 19,
            },
          },
          {
            order: 5,
            option_id: {
              content_rich: "<p>Tamamen Katılıyorum</p>",
              id: 20,
            },
          },
        ],
      },
    },
    {
      order: 4,
      question_id: {
        content_rich:
          "<p>Altta kalmamak i&ccedil;in diğerlerinin başarılarını k&uuml;&ccedil;&uuml;msediğim olur.</p>",
        duration: null,
        is_mandatory: false,
        wait_duration: null,
        answer_duration: null,
        id: "d7f98b79-c596-46c7-bff7-4904bd448f8b",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p>Hi&ccedil; Katılmıyorum</p>",
              id: 16,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>Katılmıyorum</p>",
              id: 17,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>Fikrim Yok</p>",
              id: 18,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>Katılıyorum</p>",
              id: 19,
            },
          },
          {
            order: 5,
            option_id: {
              content_rich: "<p>Tamamen Katılıyorum</p>",
              id: 20,
            },
          },
        ],
      },
    },
  ];
  const fakeDataGeneral = [
    {
      questionNumb: 1,
      order: 23,
      question_id: {
        content_rich: `<div class="page" title="Page 17">\n<div class="layoutArea">\n<div class="column">\n<p>"KAPI" ile "DUVAR" arasındaki ilişki, "TÜNEL" ile aşağıdaki kelimelerden hangisi arasında vardır?</p>\n<div class="page" title="Page 17">\n<div class="layoutArea">\n<div class="column">\n</div>\n</div>\n</div>\n</div>\n</div>\n</div>`,
        duration: null,
        is_mandatory: true,
        wait_duration: null,
        answer_duration: null,
        id: "1x",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p>Araba</p>",
              id: 1,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>Dağ</p>",
              id: 2,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>Nehir</p>",
              id: 3,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>Köprü</p>",
              id: 4,
            },
          },
        ],
      },
    },
    {
      questionNumb: 2,
      order: 24,
      question_id: {
        content_rich: `<p><img src=${fakeQuestion} alt="Uzamsal S17" /></p>\n<div class="page" title="Page 17">\n<div class="layoutArea">\n<div class="column">\n<p>Örgüyü tamamlamak için soru işareti olan yere hangisi gelmelidir?</p>\n<div class="page" title="Page 17">\n<div class="layoutArea">\n<div class="column">\n</div>\n</div>\n</div>\n</div>\n</div>\n</div>`,
        duration: null,
        is_mandatory: true,
        wait_duration: null,
        answer_duration: null,
        id: "2x",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p>A</p>",
              id: 262,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>B</p>",
              id: 263,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>C</p>",
              id: 264,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>D</p>",
              id: 265,
            },
          },
          {
            order: 5,
            option_id: {
              content_rich: "<p>E</p>",
              id: 266,
            },
          },
        ],
      },
    },
  ];
  const fakeDataEnglish = [
    {
      questionNumb: 1,
      order: 23,
      question_id: {
        content_rich: `<div class="page" title="Page 17">\n<div class="layoutArea">\n<div class="column">\n<p>"In 2010, when I ________ four years old, my father ________ me to see the Northern Lights in the Scandinavian Region.</p>\n<div class="page" title="Page 17">\n<div class="layoutArea">\n<div class="column">\n</div>\n</div>\n</div>\n</div>\n</div>\n</div>`,
        duration: null,
        is_mandatory: true,
        wait_duration: null,
        answer_duration: null,
        id: "1x",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p> was / took</p>",
              id: 1,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>were / taken</p>",
              id: 2,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>have been / takes</p>",
              id: 3,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>was / take</p>",
              id: 4,
            },
          },
        ],
      },
    },
    {
      questionNumb: 2,
      order: 24,
      question_id: {
        content_rich:
          '<p>When was the shirt bought?</p>\n<p><audio controls="controls"><source src="https://admin.hiri.app/assets/6ec6bcb8-75d2-4359-9956-51a827445fe9" type="audio/mpeg" /></audio></p>',
        duration: null,
        is_mandatory: true,
        wait_duration: null,
        answer_duration: null,
        id: "489526cc-5027-450b-95f1-ec7b7dd541f2",
        options: [
          {
            order: 1,
            option_id: {
              content_rich: "<p>yesterday</p>",
              id: 340,
            },
          },
          {
            order: 2,
            option_id: {
              content_rich: "<p>three days ago</p>",
              id: 341,
            },
          },
          {
            order: 3,
            option_id: {
              content_rich: "<p>last week</p>",
              id: 342,
            },
          },
          {
            order: 4,
            option_id: {
              content_rich: "<p>last month</p>",
              id: 343,
            },
          },
        ],
      },
    },
  ];
  const [fakeData, setFakeData] = useState(
    activeExamData.exam.category === "Personality Test"
      ? fakeDataPersonal
      : activeExamData.exam.category === "english"
      ? fakeDataEnglish
      : fakeDataGeneral
  );
  const [questionsPerPage] = useState(
    activeExamData.exam.category === "Personality Test" ? 2 : 1
  );
  const totalPages = Math.ceil(fakeData.length / questionsPerPage);
  function getQuestionsForPage(pageNumber) {
    const startIndex = (pageNumber - 1) * questionsPerPage;
    const endIndex = startIndex + questionsPerPage;
    return fakeData.slice(startIndex, endIndex);
  }
  const [currentPage, setCurrentPage] = useState(1);
  const [currentQuestions, setCurrentQuestions] = useState(
    getQuestionsForPage(currentPage)
  );
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
    setCurrentQuestions(getQuestionsForPage(pageNumber));
  }
  const [answers, setAnswers] = useState({});
  function handleAnswerChange(questionId, answer) {
    setAnswers((prevAnswers) => {
      return {
        ...prevAnswers,
        [questionId]: answer,
      };
    });
  }
  const stableHeads = [
    "Kesinlikle Katılmıyorum",
    "Katılmıyorum",
    "Kararsızım",
    "Katılıyorum",
    "Kesinlikle Katılıyorum",
  ];
  const isAllAnswered = () => {
    for (let i = 0; i < currentQuestions.length; i++) {
      const questionId = currentQuestions[i].question_id.id;
      if (!answers.hasOwnProperty(questionId)) {
        return false;
      }
    }
    return true;
  };
  const [isNextOk, setIsNextOk] = useState(false);
  useEffect(() => {
    setIsNextOk(false);
    setIsNextOk(isAllAnswered());
  }, [answers, currentPage]);
  const PersonalExamTemplate = () => {
    return (
      <div className="inventory transition-3">
        <div className="bottom">
          <div className="survey-container">
            <div className="line head">
              <div className="row first-fix">
                <div className="text">{"No"}</div>
              </div>
              <div className="row big">
                <div className="text" style={{ fontWeight: 600 }}>
                  Soru
                </div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[0]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[1]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[2]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[3]}</div>
              </div>
              <div className="row no-border">
                <div className="text">{stableHeads[4]}</div>
              </div>
            </div>
            {currentQuestions.map((question, index) => {
              return (
                <div
                  className={
                    index === currentQuestions.length - 1
                      ? "line no-border"
                      : "line"
                  }
                  key={index}
                >
                  <div className="row first-fix">{question.order}</div>
                  <div
                    className="row big"
                    data-responsive={"Soru " + question.order}
                    dangerouslySetInnerHTML={{
                      __html: question.question_id.content_rich,
                    }}
                  />
                  <>
                    {question.question_id.options.map((option, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            index === question.question_id.options.length - 1
                              ? "row no-border"
                              : "row"
                          }
                          data-responsive={stableHeads[index]}
                        >
                          {!isDesktop && <div className="border-supp" />}
                          <input
                            type="radio"
                            name={`answer-${question.question_id.id}`}
                            value={option.option_id.id}
                            onChange={() => {
                              handleAnswerChange(
                                question.question_id.id,
                                option.option_id.id
                              );
                            }}
                            checked={
                              answers[question.question_id.id] ===
                              option.option_id.id
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                </div>
              );
            })}
          </div>
        </div>
        <div className="step-controller fix">
          <div className="info box">
            <div>
              <span>{fakeData.length}</span> Sorudan
            </div>
            <div>
              <span>
                {currentQuestions[0].order +
                  "-" +
                  currentQuestions[currentQuestions.length - 1].order +
                  " "}
              </span>
              arası gösteriliyor.
            </div>
          </div>
          <div className="steps box">
            <Timeline
              count={totalPages}
              isActive={currentPage - 1}
              setIsActive={setController}
              circle={totalPages}
            />
          </div>
          {isNextOk ? (
            <button
              className="next-button transform-3"
              onClick={(e) => {
                window.scroll(0, 0);
                if (currentPage === totalPages) {
                  dispatch(changeExamStep("realExamInfo"));
                } else {
                  handlePageChange(currentPage + 1);
                }
              }}
            >
              {currentPage === totalPages ? "Tamamla" : "İleri"}
              <span>
                <img src={next} alt="icon" />
              </span>
              <br />
            </button>
          ) : (
            <button className="next-button" disabled={true}>
              {currentPage === totalPages ? "Tamamla" : "İleri"}
              <span>
                <img src={next} alt="icon" />
              </span>
              <br />
            </button>
          )}
        </div>
      </div>
    );
  };
  const TestTemplate = () => {
    return (
      <div className="inventory transition-3">
        <div
          className={"general-exam-wrapper test containImg"}
          id={"scrollY"}
          style={{ scrollBehavior: "smooth" }}
        >
          <div className="g-left">
            <strong>{currentQuestions[0].questionNumb}.</strong>
            <div
              className="question"
              dangerouslySetInnerHTML={{
                __html: currentQuestions[0].question_id.content_rich,
              }}
            />
          </div>
          <div className="seperator" />
          <div className="g-right">
            <div className="info">Sadece bir şık seçin.</div>
            <div className="radio-container">
              {currentQuestions[0].question_id.options.map((option, index) => {
                return (
                  <div key={index} className="radio-group">
                    <input
                      type="radio"
                      id={option.order}
                      name="group"
                      onChange={(e) => {
                        handleAnswerChange(
                          currentQuestions[0].question_id.id,
                          option.option_id.id
                        );
                      }}
                      checked={
                        answers[currentQuestions[0].question_id.id] ===
                        option.option_id.id
                      }
                    />
                    <label
                      htmlFor={option.order}
                      dangerouslySetInnerHTML={{
                        __html: option.option_id.content_rich,
                      }}
                    ></label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="step-controller fix">
          <div className="info box">
            <div>
              <span>{2}</span> Sorudan
            </div>
            <div>
              <strong>{currentQuestions[0].questionNumb}.</strong> soru
              gösteriliyor.
            </div>
          </div>
          <div className="steps box" style={{ opacity: 0 }}></div>
          {isNextOk ? (
            <>
              {currentPage === totalPages ? (
                <button
                  className="next-button transform-3"
                  onClick={(e) => {
                    if (currentPage === totalPages) {
                      dispatch(changeExamStep("realExamInfo"));
                    } else {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  Tamamla
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              ) : (
                <button
                  className="next-button transform-3"
                  disabled={currentPage === totalPages ? true : false}
                  onClick={(e) => {
                    if (currentPage === totalPages) {
                      dispatch(changeExamStep("realExamInfo"));
                    } else {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  İleri
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              )}
            </>
          ) : (
            <button className="next-button" disabled={true}>
              İleri
              <span>
                <img src={next} alt="icon" />
              </span>
            </button>
          )}
        </div>
      </div>
    );
  };
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hide, setHide] = useState(false);
  let x = document.getElementById("sample");
  let audioElement = useRef();
  useEffect(() => {
    if (localStorage.getItem(`_count_`) >= 2) {
      setHide(true);
    } else {
      setHide(false);
    }
    if (containsAudioTag(currentQuestions[0].question_id.content_rich)) {
      console.log("var");
      audioElement.current = new Audio(
        currentQuestions[0].question_id.content_rich.match(
          /src=["'](.*?)["']/
        )[1]
      );
      // Component yüklendiğinde localStorage'dan play sayısını kontrol et
      const playCountFromLocalStorage = localStorage.getItem(`_count_`);
      if (playCountFromLocalStorage) {
        setPlayCount(parseInt(playCountFromLocalStorage));
      }
      if (!containsAudioTag(currentQuestions[0].question_id.content_rich))
        return;
      const audio = new Audio(
        currentQuestions[0].question_id.content_rich.match(
          /src=["'](.*?)["']/
        )[1]
      );
      audio.addEventListener("loadedmetadata", () => {
        console.log("Duration:", audio.duration);
        setDuration(Math.floor(audio.duration));
      });
      return () => {
        audio.removeEventListener("loadedmetadata", () => {});
      };
    }
  }, [currentQuestions]);
  const playAudio = () => {
    setTime(0);
    setTimeout(() => {
      setIsPlaying(false);
    }, x.duration * 1000);
    audioElement.current
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.log("Ses çalınamadı:", error);
      });
    if (playCount < 2) {
      setIsPlaying(true);
      setPlayCount(playCount + 1);
    }
  };
  const [playCount, setPlayCount] = useState(0); // Play butonu basma sayısını takip eden state
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(null);
  useEffect(() => {
    let timer = null;
    timer = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [time]);
  const stopAudio = (e) => {
    setPlayCount(0);
    localStorage.removeItem(`_count_`);
    setIsPlaying(false);
    setTime(0);
    audioElement.current.pause();
    audioElement.current.currentTime = 0;
  };
  function containsAudioTag(str) {
    const regex = /<audio\b[\s\S]*?>/i;
    return regex.test(str);
  }
  function containsImgTag(str) {
    const regex = /<img[\s\S]*?>/i;
    return regex.test(str);
  }
  const EngTestTemplate = () => {
    return (
      <>
        <div
          className={
            containsImgTag(
              currentQuestions[0].question_id.options[0].option_id.content_rich
            )
              ? "general-exam-wrapper test containImg"
              : "general-exam-wrapper test notContainImg"
          }
          id={"scrollY"}
          style={{ scrollBehavior: "smooth" }}
        >
          <div className="g-left">
            <strong></strong>
            {containsAudioTag(currentQuestions[0].question_id.content_rich) ? (
              <>
                <div
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html:
                      currentQuestions[0].question_id.content_rich.replace(
                        /<audio.*?<\/audio>/,
                        ""
                      ),
                  }}
                />
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    margin: "0 auto",
                    marginTop: "30px",
                    columnGap: "10px",
                  }}
                >
                  {isPlaying ? (
                    <div className="audio-wrapper-fixed">
                      <AudioReverse initialTime={Math.round(time)} />
                      <div>/</div>
                      <AudioReverse initialTime={duration} />
                    </div>
                  ) : (
                    <div className="audio-wrapper-fixed"></div>
                  )}
                </div>
                {playCount === 2 ? (
                  <div className="audio-question">
                    <button className="playButton" disabled={true}>
                      <div>Dinle</div>
                    </button>
                    <p>
                      <strong>0</strong> dinleme hakkınız kaldı.
                    </p>
                  </div>
                ) : (
                  <div className="audio-question">
                    <audio
                      ref={audioRef}
                      id="sample"
                      src={
                        currentQuestions[0].question_id.content_rich.match(
                          /src=["'](.*?)["']/
                        )[1]
                      }
                    />
                    {!isPlaying ? (
                      <button
                        className="playButton"
                        onClick={playAudio}
                        disabled={hide}
                      >
                        <img src={play} />
                        <div>Dinle</div>
                      </button>
                    ) : (
                      <>
                        <button className="playButton" disabled>
                          <div>Oynatılıyor...</div>
                        </button>
                      </>
                    )}
                    <p>
                      {playCount === 0 ? (
                        <strong>2</strong>
                      ) : playCount === 1 ? (
                        <strong>1</strong>
                      ) : (
                        <strong>0</strong>
                      )}{" "}
                      dinleme hakkınız kaldı.
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div
                className="question"
                dangerouslySetInnerHTML={{
                  __html: currentQuestions[0].question_id.content_rich,
                }}
              />
            )}
          </div>
          <div className="seperator" />
          <div className="g-right">
            <div className="info">Sadece bir şık seçin.</div>
            <div className="radio-container">
              {currentQuestions[0].question_id.options.map((option, index) => {
                return (
                  <div key={index} className="radio-group">
                    <input
                      type="radio"
                      id={option.order}
                      name="group"
                      onChange={(e) => {
                        handleAnswerChange(
                          currentQuestions[0].question_id.id,
                          option.option_id.id
                        );
                      }}
                      checked={
                        answers[currentQuestions[0].question_id.id] ===
                        option.option_id.id
                      }
                    />
                    <label
                      htmlFor={option.order}
                      dangerouslySetInnerHTML={{
                        __html: option.option_id.content_rich,
                      }}
                    ></label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="step-controller fix">
          <div className="info box">
            <div>
              <span>{2}</span> Sorudan
            </div>
            <div>
              <strong>{currentQuestions[0].questionNumb}.</strong> soru
              gösteriliyor.
            </div>
          </div>
          <div className="steps box" style={{ opacity: 0 }}></div>
          {isNextOk ? (
            <>
              {currentPage === totalPages ? (
                <button
                  id="tamamla"
                  className="next-button transform-3"
                  onClick={(e) => {
                    if (
                      containsAudioTag(
                        currentQuestions[0].question_id.content_rich
                      )
                    ) {
                      stopAudio();
                    }
                    if (currentPage === totalPages) {
                      dispatch(changeExamStep("realExamInfo"));
                    } else {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  Tamamla
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              ) : (
                <button
                  className="next-button transform-3"
                  disabled={currentPage === totalPages ? true : false}
                  onClick={(e) => {
                    if (
                      containsAudioTag(
                        currentQuestions[0].question_id.content_rich
                      )
                    ) {
                      stopAudio();
                    }
                    if (currentPage === totalPages) {
                      dispatch(changeExamStep("realExamInfo"));
                    } else {
                      handlePageChange(currentPage + 1);
                    }
                  }}
                >
                  İleri
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              )}
            </>
          ) : (
            <button className="next-button" disabled={true}>
              İleri
              <span>
                <img src={next} alt="icon" />
              </span>
            </button>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      {activeExamData.exam.category === "Personality Test"
        ? PersonalExamTemplate()
        : activeExamData.exam.category === "english"
        ? EngTestTemplate()
        : TestTemplate()}
    </>
  );
};
