import React, { useState } from "react";
import arrow from "../assets/svg/icons/DROPDOWN_ARROW_DOWN.svg";
const DropdownBasicWebcam = (props) => {
  const {
    selected = { deviceId: "default", label: "test" },
    setSelected,
    options,
    placeHolder = "test",
    icon = false,
  } = props;
  const [showOptions, setShowOptions] = useState(false);
  const toggleOptions = () => setShowOptions(!showOptions);
  function simplifyDeviceName(name) {
    const regex = /\((.*?)\)/g;
    const matches = name.match(regex);
    let simplifiedName = name;
    if (matches && matches.length >= 2) {
      simplifiedName = name.replace(matches[1], "");
    }
    return simplifiedName.trim();
  }
  return (
    <div className="dropdown-container">
      <div className="selected-option " onClick={toggleOptions}>
        {!!icon && <img src={icon} alt="icon" />}
        <div className="flex items-center justify-center  ">
          {(selected !== null && simplifyDeviceName(selected.label)) ||
            placeHolder}
        </div>
        {showOptions ? (
          <img src={arrow} alt="icon" className="reverse" />
        ) : (
          <img src={arrow} alt="icon" />
        )}
      </div>
      {showOptions && (
        <ul className="options">
          {options.length !== 0 ? (
            <>
              {options.map((option) => {
                if (option.deviceId === "default") return;
                if (option.deviceId === "communications") return;
                return (
                  <li
                    key={option.deviceId}
                    className="option"
                    onClick={() => {
                      if (option.deviceId === "") return;
                      if (option.deviceId === "default") return;
                      setSelected(option);
                      toggleOptions();
                    }}
                  >
                    {option.deviceId === "" ? (
                      <div
                        style={{
                          cursor: "not-allowed",
                          opacity: "0.2",
                          padding: 0,
                        }}
                        className="option disabled"
                      >
                        Lütfen tarayıcı izinlerinizi kontrol edin!
                      </div>
                    ) : (
                      <>{simplifyDeviceName(option.label)}</>
                    )}
                  </li>
                );
              })}
            </>
          ) : (
            <li
              className="option disabled"
              style={{ cursor: "not-allowed", opacity: "0.2" }}
            >
              Cihaz Algılanamadı!
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
export default DropdownBasicWebcam;
