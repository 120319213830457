import { call, put, takeEvery } from "redux-saga/effects";
import { getFetchSuccess } from "./testState";

// function* sideEffectFunc() {
//   const testData = yield call(() =>
//     fetch("https://api.thecatapi.com/v1/breeds")
//   );
//   const formatted = yield testData.json();
//   const finalData = formatted.slice(0, 10);
//   yield put(getFetchSuccess(finalData));
// }
function* urlDetection() {
  alert("url changed");
}

// export function* testSaga() {
//   yield takeEvery("test/getFetch", sideEffectFunc);
// }
export function* urlDetect() {
  yield takeEvery("url/urlChanged", urlDetection);
}
