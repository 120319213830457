import { createSlice } from "@reduxjs/toolkit";

export const testSlice = createSlice({
  name: "test",
  initialState: {
    data: [],
    isLoading: false,
  },
  reducers: {
    getFetch: (state) => {
      state.isLoading = true;
    },
    getFetchSuccess: (state, action) => {
      state.data = action.payload;
    },
    getFetchFailure: (state) => {
      state.isLoading = false;
    },
  },
});

export const { getFetch, getFetchFailure, getFetchSuccess } = testSlice.actions;
export default testSlice.reducer;
