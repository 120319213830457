import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeExamStep } from "../../app/examActions/action";
import nextIconDisable from "../../assets/svg/icons/ARROW_DISABLE.svg";
import nextIcon from "../../assets/svg/icons/NEXTARROW.svg";
const Step2 = () => {
  const dispatch = useDispatch();
  const [step2Btn, setStep2Btn] = useState(false);
  const [kvkk, setKvkk] = useState(false);
  const { activeExamData } = useSelector((store) => store.assessments);
  useEffect(() => {
    setStep2Btn(kvkk);
  }, [kvkk]);
  return (
    <>
      <div className="step step-2">
        <div className="ruleset">
          <div className="head">
            Teste başlamadan önce bilinmesi gerekenler:
          </div>
          <ul className="rules">
            <li>
              {activeExamData.exam.category === "Personality Test"
                ? "İşlevsel Kişilik Envanteri testi"
                : activeExamData.exam.category === "General Apitute Test"
                ? "Genel Yetenek testi"
                : "İngilizce testi"}
              , {activeExamData.exam.questions.length} adet sorudan
              oluşmaktadır. Testi tamamlamanız için size verilen süre{" "}
              {activeExamData.exam.duration} dakikadır.
            </li>
            <li>
              Kalan süre bilgisi, sağ üstteki mor kutucuk içinde
              gösterilmektedir. Lütfen tarayıcınıza kamera ve mikrofon kullanma
              izinlerini verdiğinizden emin olunuz.
            </li>
            <li>
              Test süresince farklı aralıklarda güvenlik amaçlı olarak ekran
              görüntünüz kaydedilecektir.
            </li>
            <li>Test boyunca tam ekran modundan çıkmayınız.</li>
          </ul>
        </div>
        <div className="bottom">
          <div className="check">
            <input
              type="checkbox"
              id="test"
              checked={kvkk}
              onChange={(e) => {
                setKvkk(!kvkk);
              }}
            />
            <label>
              <span
                onClick={(e) => {
                  window.open(
                    "https://docs.google.com/document/d/1u0LokI0j9qDmg7h-B7YrmlR-4gR7Kc1yihsb6p_xXxk/edit"
                  );
                }}
              >
                KVKK sözleşmesini
              </span>{" "}
              okudum ve onayladım.
            </label>
          </div>
          {step2Btn ? (
            <div
              className="next-button-step transform-3"
              onClick={(e) => {
                dispatch(changeExamStep("fakeInfo"));
                window.scroll(0, 0);
              }}
            >
              İleri <img src={nextIcon} alt="icon" />
            </div>
          ) : (
            <div className="next-button-step disabled">
              İleri <img src={nextIconDisable} alt="icon" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Step2;
