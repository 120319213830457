import { createSlice } from "@reduxjs/toolkit";
const siteSlice = createSlice({
  name: "site",
  initialState: {
    menuOpen: false,
    activeMenuRoot: null,
  },
  reducers: {
    toggleMenu(state) {
      state.menuOpen = !state.menuOpen;
    },
    setMenuRoot(state, action) {
      state.activeMenuRoot = action.payload;
    },
  },
});
export default siteSlice.reducer;
export const { toggleMenu, setMenuRoot } = siteSlice.actions;
