import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetJWT } from "../utils/CookieHelper";

export const Error = () => {
  const navigate = useNavigate();
  const token = GetJWT();

  useEffect(() => {
    if (token.accessToken) navigate("/");
    else {
      navigate("login");
    }
  }, [navigate]);

  return null;
};
