import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { changeExamStep } from "../../app/examActions/action";
import { ExamPage } from "./ExamPage";
import FakeExamInformation from "./FakeExamInformation";
import { FakeExam } from "./FakeExam";
import RealExamInfo from "./RealExamInfo";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { useNavigate } from "react-router-dom";
const Steps = () => {
  const { activeExamStep } = useSelector((store) => store.exam);
  const { activeConfig } = useSelector((store) => store.assessments);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (activeConfig.last_page > 1) {
      dispatch(changeExamStep(1));
    } else if (activeConfig.last_page === -1) {
      navigate("/");
    } else {
      dispatch(changeExamStep("first"));
    }
  }, [activeConfig.last_page]);
  useEffect(() => {
    document.querySelector(".hiri-container").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [activeExamStep]);

  return activeExamStep === "first" ? (
    <Step1 />
  ) : activeExamStep === "second" ? (
    <Step2 />
  ) : activeExamStep === "third" ? (
    <Step3 />
  ) : activeExamStep === "fakeInfo" ? (
    <FakeExamInformation />
  ) : activeExamStep === "fakeExam" ? (
    <FakeExam />
  ) : activeExamStep === "realExamInfo" ? (
    <RealExamInfo />
  ) : activeExamStep === 1 ? (
    <ExamPage />
  ) : null;
};
export default Steps;
