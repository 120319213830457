import React, { useState, useRef, useEffect } from "react";
import play from "../assets/svg/play-icon.svg";
const AudioVisualizer = ({ mp3, id }) => {
  const canvasRef = useRef(null);
  const audioRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const [centerX, setCenterX] = useState(0); // Ortalanacak X koordinatını state olarak tanımla
  const [playCount, setPlayCount] = useState(0); // Play butonu basma sayısını takip eden state

  useEffect(() => {
    // Component yüklendiğinde localStorage'dan play sayısını kontrol et
    const playCountFromLocalStorage = localStorage.getItem(`_count_${id}`);
    if (playCountFromLocalStorage) {
      setPlayCount(parseInt(playCountFromLocalStorage));
    }
  }, []);
  let context;

  const handlePlay = () => {
    context = new AudioContext();
    context.resume().then(() => {});
    if (playCount < 2) {
      // Kullanıcının 2 kere play butonuna basma hakkı varsa
      if (audioContextRef.current.state === "suspended") {
        audioContextRef.current.resume();
      }
      audioRef.current.play();
      setIsPlaying(true);
      setPlayCount(playCount + 1);
      localStorage.setItem(`_count_${id}`, playCount + 1); // localStorage'a play sayısını güncelle
    }
  };

  const handleEnded = () => {
    // Ses bittiğinde spektrumu temizlemek için canvas'i sıfırla ve centerX'i sıfırla
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    ctx.clearRect(0, 0, WIDTH, HEIGHT);
    setCenterX(0);
    setIsPlaying(false);
  };
  const setupAudio = () => {
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    analyserRef.current = audioContextRef.current.createAnalyser();
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const source = audioContextRef.current.createMediaElementSource(
      audioRef.current
    );
    source.connect(analyserRef.current);
    analyserRef.current.connect(audioContextRef.current.destination);
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const drawVisualizer = () => {
      const WIDTH = canvas.width;
      const HEIGHT = canvas.height;
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      analyserRef.current.getByteFrequencyData(dataArray);
      const barWidth = (WIDTH / bufferLength) * 2.5;
      let barHeight;
      let x = 0;
      for (let i = 0; i < bufferLength; i += 5) {
        barHeight = dataArray[i] / 2;

        // Canvas'i nokta olarak çizme
        ctx.beginPath();
        ctx.arc(
          x + barWidth,
          HEIGHT - barHeight,
          5, // Nokta boyutunu 2x2 olarak ayarlama
          0,
          10 * Math.PI
        );
        ctx.fillStyle = `blue`;
        ctx.fill();
        if (i === bufferLength / 2) {
          // Dalgalandığı yerin X koordinatını ortalamak için centerX değerini güncelle
          setCenterX(x);
        }
        x += barWidth + 20;
      }
      requestAnimationFrame(drawVisualizer);
    };

    drawVisualizer();
  };
  const handleAudioLoad = () => {
    var context = new AudioContext();
    setupAudio();
  };
  const [isPlaying, setIsPlaying] = useState(false);
  const [hide, setHide] = useState(false);
  useEffect(() => {
    if (localStorage.getItem(`_count_${id}`) >= 2) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, []);

  return (
    <>
      <div className="audio-question">
        <div className="spec-area">
          <div className="canvas-wrapper">
            <canvas ref={canvasRef} />
          </div>
        </div>
        <audio
          ref={audioRef}
          src={mp3}
          onLoadedData={handleAudioLoad}
          onEnded={handleEnded}
        ></audio>
        {!isPlaying ? (
          <button className="playButton" onClick={handlePlay} disabled={hide}>
            <img src={play} />
            <div>Dinle</div>
          </button>
        ) : (
          <button className="playButton" disabled>
            <div>Oynatılıyor...</div>
          </button>
        )}
        <p>
          {playCount === 0 ? (
            <strong>2</strong>
          ) : playCount === 1 ? (
            <strong>1</strong>
          ) : (
            <strong>0</strong>
          )}{" "}
          dinleme hakkınız kaldı.
        </p>
      </div>
    </>
  );
};

export default AudioVisualizer;
