import {  Navigate } from "react-router-dom";
import { GetJWT } from "../../utils/CookieHelper";
import {  SetReturnUrl } from "../../utils/RedirectHelper";
import { useDispatch } from "react-redux";


export const ProtectedRoute = ({ loginRequired = true, children }) => {
  const { accessToken, refreshToken } = GetJWT();
  const dispatch = useDispatch();

  if (!loginRequired && !!refreshToken) {
    return <Navigate to={"/"} replace={true} />;
  }

  if (loginRequired && !refreshToken) {
    const returnUrl = SetReturnUrl();
    return <Navigate to={returnUrl} />;
  }

  // if (!accessToken) {
  //   // dispatch loading screen
  //   dispatch(toggleLoading(true));
  // }

  return children;
};
