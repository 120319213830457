import { GetJWT, setJWT } from "../../utils/CookieHelper";
import { Instance, AuthInstance } from "./authInstances";

export const loginUser = async function (loginData) {
  return Instance({
    url: "/auth/login",
    method: "POST",
    data: {
      email: loginData.email,
      password: loginData.password,
      mode: "json",
      otp: "string",
    },
  });
};
export const refreshAuthToken = async function (refresh_token, config) {
  return Instance({
    url: "/auth/refresh",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      refresh_token,
      mode: "json",
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        setJWT(res.data.data);
        config.headers.Authorization = `Bearer ${res.data.data.access_token}`;
        return AuthInstance(config ?? res.config).then((res) => res);
      }
    })
    .catch((err) => {});
};
