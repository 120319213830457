import React, { useEffect, useRef, useState } from "react";
import Timeline from "../../components/Timeline";
import play from "../../assets/svg/play-icon.svg";
import next from "../../assets/svg/icons/NEXTARROW.svg";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { answerQuestion, updateStatus } from "../../app/assessments/actions";
import { toggleLoading } from "../../app/auth/actions";
import AudioReverse from "../../components/TimerComponents/AudioReverseComponent";
export const ExamPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const [isNextOk, setIsNextOk] = useState(false);
  const { activeExamData, activeConfig } = useSelector(
    (store) => store.assessments
  );
  const { profileData } = useSelector((store) => store.auth);
  // sınav kaç sayfadan oluştuğunu hesaplıyorum.
  const totalPages = Math.ceil(
    activeExamData.exam.questions.length / activeExamData.question_per_page
  );
  // MOBILE CHECK
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };
  const isDesktop = useMediaQuery("(min-width: 1200px)");
  function getQuestionsForPage(pageNumber) {
    const startIndex = (pageNumber - 1) * activeExamData.question_per_page;
    const endIndex = startIndex + activeExamData.question_per_page;
    return activeExamData.exam.questions.slice(startIndex, endIndex);
  }
  const [currentPage, setCurrentPage] = useState(
    activeConfig.last_page > 1 ? activeConfig.last_page - 1 : 1
  );
  const [currentQuestions, setCurrentQuestions] = useState(
    getQuestionsForPage(currentPage)
  );
  function handlePageChange(pageNumber) {
    setCurrentPage(pageNumber);
    setCurrentQuestions(getQuestionsForPage(pageNumber));
  }
  const [answers, setAnswers] = useState({});
  function handleAnswerChange(questionId, answer) {
    setAnswers({
      ...answers,
      [questionId]: answer,
    });
  }
  const stableHeads = [
    "Kesinlikle Katılmıyorum",
    "Katılmıyorum",
    "Kararsızım",
    "Katılıyorum",
    "Kesinlikle Katılıyorum",
  ];
  const isAllAnswered = () => {
    for (let i = 0; i < currentQuestions.length; i++) {
      const questionId = currentQuestions[i].question_id.id;
      if (!answers.hasOwnProperty(questionId)) {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    setIsNextOk(false);
    setIsNextOk(isAllAnswered());
  }, [answers, currentPage]);
  // track current page
  useEffect(() => {
    document.getElementById("scrollY").scroll(0, 0);
    document.getElementById("scroll").scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (currentPage === 1) {
      dispatch(
        updateStatus({
          id: searchParams.get("id"),
          status: "inprogress",
          last_page: 2,
        })
      );
    } else {
      dispatch(
        updateStatus({
          id: searchParams.get("id"),
          status: "inprogress",
          last_page: currentPage + 1,
        })
      );
    }
  }, [currentPage]);
  // bir soruya kaç saniye harcandı
  const soruSuresiRef = useRef(0);
  useEffect(() => {
    const sayac = setInterval(() => {
      soruSuresiRef.current += 1; // useRef ile değiştirilen değer
    }, 1000);
    return () => clearInterval(sayac);
  }, []);
  // soru görsel içeriyor mu ?
  function containsImgTag(str) {
    const regex = /<img[\s\S]*?>/i;
    return regex.test(str);
  }
  function containsAudioTag(str) {
    const regex = /<audio\b[\s\S]*?>/i;
    return regex.test(str);
  }
  // bir bug olması durumunda lastpage i -1 e çekerek adayın sınavını iptal ediyoruz
  useEffect(() => {
    if (currentPage > totalPages) {
      dispatch(
        updateStatus({
          id: searchParams.get("id"),
          status: "canceled",
          last_page: -1,
        })
      );
    }
  }, [currentPage, totalPages]);

  const PersonalExamTemplate = () => {
    return (
      <div className="inventory transition-3">
        <div className="bottom scroll">
          <div
            className="survey-container"
            id={"scrollY"}
            style={{ scrollBehavior: "smooth" }}
          >
            <div className="line head">
              <div className="row first-fix">
                <div className="text">{"No"}</div>
              </div>
              <div className="row big">
                <div className="text" style={{ fontWeight: 600 }}>
                  Soru
                </div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[0]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[1]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[2]}</div>
              </div>
              <div className="row">
                <div className="text">{stableHeads[3]}</div>
              </div>
              <div className="row no-border">
                <div className="text">{stableHeads[4]}</div>
              </div>
            </div>
            {currentQuestions.map((question, index) => {
              return (
                <div
                  className={
                    index === currentQuestions.length - 1
                      ? "line no-border"
                      : "line"
                  }
                  key={index}
                >
                  <div className="row first-fix" data-responsive="No">
                    {index + 1 + (activeConfig.last_page - 2) * 10}
                  </div>
                  <div
                    className="row big"
                    data-responsive={
                      (activeConfig.last_page - 2) * 10 + index + 1
                    }
                    dangerouslySetInnerHTML={{
                      __html: question.question_id.content_rich,
                    }}
                  />
                  <>
                    {question.question_id.options.map((option, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            index === question.question_id.options.length - 1
                              ? "row no-border"
                              : "row"
                          }
                          data-responsive={stableHeads[index]}
                        >
                          {!isDesktop && <div className="border-supp" />}
                          <input
                            type="radio"
                            name={`answer-${question.question_id.id}`}
                            onChange={() => {
                              let data = {
                                candidate: profileData.id,
                                assessment: params.id,
                                question_id: question.question_id.id,
                                option_id: option.option_id.id,
                                duration: soruSuresiRef.current,
                              };
                              dispatch(answerQuestion(data))
                                .unwrap()
                                .then((originalPromiseResult) => {
                                  handleAnswerChange(
                                    question.question_id.id,
                                    option.option_id.id
                                  );
                                  soruSuresiRef.current = 0;
                                })
                                .catch((rejectedValueOrSerializedError) => {
                                  console.log(rejectedValueOrSerializedError);
                                });
                            }}
                            checked={
                              answers[question.question_id.id] ===
                              option.option_id.id
                            }
                          />
                        </div>
                      );
                    })}
                  </>
                </div>
              );
            })}
          </div>
        </div>
        <div className="step-controller fix">
          <div className="info box">
            <div>
              <span>{activeExamData.exam.questions.length}</span> Sorudan
            </div>
            <div>
              <span>
                {`${
                  currentQuestions.length +
                  (activeConfig.last_page - 3) * 10 +
                  1
                }-${
                  currentQuestions.length +
                  (activeConfig.last_page - 3) * 10 +
                  10
                } `}
              </span>
              arası gösteriliyor.
            </div>
          </div>
          <div className="steps box">
            <Timeline
              count={totalPages}
              isActive={currentPage - 1}
              circle={totalPages}
            />
          </div>
          {isNextOk ? (
            <>
              {currentPage === totalPages ? (
                <button
                  className="next-button transform-3"
                  id="tamamla"
                  onClick={(e) => {
                    document.getElementById("tamamla").disabled = "true";
                    dispatch(toggleLoading(true));
                    localStorage.removeItem("hotfix");
                    dispatch(
                      updateStatus({
                        id: searchParams.get("id"),
                        status: "completed",
                      })
                    )
                      .unwrap()
                      .then((originalPromiseResult) => {
                        setTimeout(() => {
                          navigate("/");
                          dispatch(toggleLoading(false));
                        }, 2000);
                        document.getElementById("scrollY").scroll(0, 0);
                        document.getElementById("scroll").scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      })
                      .catch((rejectedValueOrSerializedError) => {
                        navigate("/done");
                        dispatch(toggleLoading(false));
                      });
                  }}
                >
                  Tamamla
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              ) : (
                <button
                  className="next-button transform-3"
                  disabled={currentPage === totalPages ? true : false}
                  onClick={(e) => {
                    handlePageChange(currentPage + 1);
                    soruSuresiRef.current = 0;
                    window.scroll(0, 0);
                  }}
                >
                  İleri
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              )}
            </>
          ) : (
            <button className="next-button" disabled={true}>
              İleri
              <span>
                <img src={next} alt="icon" />
              </span>
            </button>
          )}
        </div>
      </div>
    );
  };
  const TestTemplate = () => {
    return (
      <>
        <div
          className={
            containsImgTag(
              currentQuestions[0].question_id.options[0].option_id.content_rich
            )
              ? "general-exam-wrapper test containImg"
              : "general-exam-wrapper test notContainImg"
          }
          id={"scrollY"}
          style={{ scrollBehavior: "smooth" }}
        >
          <div className="g-left">
            <strong>{activeConfig.last_page - 1}.</strong>
            <div
              className="question"
              dangerouslySetInnerHTML={{
                __html: currentQuestions[0].question_id.content_rich,
              }}
            />
          </div>
          <div className="seperator" />
          <div className="g-right">
            <div className="info">Sadece bir şık seçin.</div>
            <div className="radio-container">
              {currentQuestions[0].question_id.options.map((option, index) => {
                return (
                  <div key={index} className="radio-group">
                    <input
                      type="radio"
                      id={option.order}
                      name="group"
                      onChange={(e) => {
                        let data = {
                          candidate: profileData.id,
                          assessment: params.id,
                          question_id: currentQuestions[0].question_id.id,
                          option_id: option.option_id.id,
                          duration: soruSuresiRef.current,
                        };
                        dispatch(answerQuestion(data))
                          .unwrap()
                          .then((originalPromiseResult) => {
                            handleAnswerChange(
                              currentQuestions[0].question_id.id,
                              option.option_id.id
                            );
                            soruSuresiRef.current = 0;
                          })
                          .catch((rejectedValueOrSerializedError) => {
                            console.log(rejectedValueOrSerializedError);
                          });
                      }}
                      checked={
                        answers[currentQuestions[0].question_id.id] ===
                        option.option_id.id
                      }
                    />
                    <label
                      htmlFor={option.order}
                      dangerouslySetInnerHTML={{
                        __html: option.option_id.content_rich,
                      }}
                    ></label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="step-controller fix">
          <div className="info box">
            <div>
              <span>{activeExamData.exam.questions.length}</span> Sorudan
            </div>
            <div>
              <strong>{activeConfig.last_page - 1}.</strong> soru gösteriliyor.
            </div>
          </div>
          <div className="steps box" style={{ opacity: 0 }}></div>
          {isNextOk ? (
            <>
              {currentPage === totalPages ? (
                <button
                  id="tamamla"
                  className="next-button transform-3"
                  onClick={(e) => {
                    document.getElementById("tamamla").disabled = "true";
                    localStorage.removeItem("hotfix");
                    dispatch(toggleLoading(true));
                    dispatch(
                      updateStatus({
                        id: searchParams.get("id"),
                        status: "completed",
                      })
                    )
                      .unwrap()
                      .then((originalPromiseResult) => {
                        setTimeout(() => {
                          navigate("/");
                          dispatch(toggleLoading(false));
                        }, 2000);
                      })
                      .catch((rejectedValueOrSerializedError) => {
                        navigate("/done");
                        dispatch(toggleLoading(false));
                      });
                  }}
                >
                  Tamamla
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              ) : (
                <button
                  className="next-button transform-3"
                  disabled={currentPage === totalPages ? true : false}
                  onClick={(e) => {
                    handlePageChange(currentPage + 1);
                    soruSuresiRef.current = 0;
                    window.scroll(0, 0);
                  }}
                >
                  İleri
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              )}
            </>
          ) : (
            <button className="next-button" disabled={true}>
              İleri
              <span>
                <img src={next} alt="icon" />
              </span>
            </button>
          )}
        </div>
      </>
    );
  };
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  let audioElement = useRef();

  useEffect(() => {
    if (containsAudioTag(currentQuestions[0].question_id.content_rich)) {
      audioElement.current = new Audio(
        currentQuestions[0].question_id.content_rich.match(
          /src=["'](.*?)["']/
        )[1]
      );
      const playCountFromLocalStorage = localStorage.getItem(
        `_count_${searchParams.get("id")}`
      );
      if (playCountFromLocalStorage) {
        setPlayCount(parseInt(playCountFromLocalStorage));
      }
    }
  }, [currentQuestions, searchParams]);
  const [time, setTime] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  useEffect(() => {
    let interval;
    if (timerOn) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timerOn]);
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    if (!containsAudioTag(currentQuestions[0].question_id.content_rich)) return;
    const audio = new Audio(
      currentQuestions[0].question_id.content_rich.match(/src=["'](.*?)["']/)[1]
    );
    audio.addEventListener("loadedmetadata", () => {
      if (!isPlaying) return;
      setDuration(Math.floor(audio.duration));
    });
    return () => {
      audio.removeEventListener("loadedmetadata", () => {});
    };
  }, [isPlaying, currentQuestions, timerOn]);
  const playAudio = () => {
    setTime(0);
    setTimerOn(true);
    audioElement.current
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.log("Ses çalınamadı:", error);
      });
    if (playCount < 2) {
      setIsPlaying(true);
      setPlayCount(playCount + 1);
      localStorage.setItem(`_count_${searchParams.get("id")}`, playCount + 1);
    }
  };
  // audionun bitiş eventi
  useEffect(() => {
    let interval;
    if (timerOn) {
      interval = setTimeout(() => {
        setIsPlaying(false);
        setTimerOn(false);
        setTime(0);
        setDuration(0);
      }, duration * 1000);
    }
    return () => clearInterval(interval);
  }, [duration]);
  const [playCount, setPlayCount] = useState(0);
  const stopAudio = (e) => {
    audioElement.current.pause();
    audioElement.current.currentTime = 0;
    setTimerOn(false);
    setTime(0);
    setIsPlaying(false);
    setDuration(0);
    setPlayCount(0);
    localStorage.removeItem(`_count_${searchParams.get("id")}`);
  };
  const EngTestTemplate = () => {
    return (
      <>
        <div
          className={
            containsImgTag(
              currentQuestions[0].question_id.options[0].option_id.content_rich
            )
              ? "general-exam-wrapper test containImg"
              : "general-exam-wrapper test notContainImg"
          }
          id={"scrollY"}
          style={{ scrollBehavior: "smooth" }}
        >
          <div className="g-left">
            <strong>{activeConfig.last_page - 1}.</strong>
            {containsAudioTag(currentQuestions[0].question_id.content_rich) ? (
              <>
                <div
                  className="question"
                  dangerouslySetInnerHTML={{
                    __html:
                      currentQuestions[0].question_id.content_rich.replace(
                        /<audio.*?<\/audio>/,
                        ""
                      ),
                  }}
                />
                <div
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    margin: "0 auto",
                    marginTop: "30px",
                    columnGap: "10px",
                    width: "100%",
                  }}
                >
                  {timerOn ? (
                    <div className="audio-wrapper-fixed">
                      <AudioReverse initialTime={Math.round(time)} />{" "}
                      <div>/</div>
                      <AudioReverse initialTime={duration} />
                    </div>
                  ) : (
                    <div className="audio-wrapper-fixed"></div>
                  )}
                </div>
                {playCount === 2 ? (
                  <div className="audio-question">
                    <button className="playButton" disabled={true}>
                      {isPlaying ? <div>Oynatılıyor...</div> : <div>Dinle</div>}
                    </button>
                    <p>
                      <strong>0</strong> dinleme hakkınız kaldı.
                    </p>
                  </div>
                ) : (
                  <div className="audio-question">
                    <audio
                      ref={audioRef}
                      id="sample"
                      src={
                        currentQuestions[0].question_id.content_rich.match(
                          /src=["'](.*?)["']/
                        )[1]
                      }
                    />
                    {!isPlaying ? (
                      <button className="playButton" onClick={playAudio}>
                        <div>Dinle</div>
                      </button>
                    ) : (
                      <button className="playButton" disabled>
                        <div>Oynatılıyor...</div>
                      </button>
                    )}
                    <p>
                      {playCount === 0 ? (
                        <strong>2</strong>
                      ) : playCount === 1 ? (
                        <strong>1</strong>
                      ) : (
                        <strong>0</strong>
                      )}{" "}
                      dinleme hakkınız kaldı.
                    </p>
                  </div>
                )}
              </>
            ) : (
              <div
                className="question"
                dangerouslySetInnerHTML={{
                  __html: currentQuestions[0].question_id.content_rich,
                }}
              />
            )}
          </div>
          <div className="seperator" />
          <div className="g-right">
            <div className="info">Sadece bir şık seçin.</div>
            <div className="radio-container">
              {currentQuestions[0].question_id.options.map((option, index) => {
                return (
                  <div key={index} className="radio-group">
                    <input
                      type="radio"
                      id={option.order}
                      name="group"
                      onChange={(e) => {
                        let data = {
                          candidate: profileData.id,
                          assessment: params.id,
                          question_id: currentQuestions[0].question_id.id,
                          option_id: option.option_id.id,
                          duration: soruSuresiRef.current,
                        };
                        dispatch(answerQuestion(data))
                          .unwrap()
                          .then((originalPromiseResult) => {
                            handleAnswerChange(
                              currentQuestions[0].question_id.id,
                              option.option_id.id
                            );
                            soruSuresiRef.current = 0;
                          })
                          .catch((rejectedValueOrSerializedError) => {
                            console.log(rejectedValueOrSerializedError);
                          });
                      }}
                      checked={
                        answers[currentQuestions[0].question_id.id] ===
                        option.option_id.id
                      }
                    />
                    <label
                      htmlFor={option.order}
                      dangerouslySetInnerHTML={{
                        __html: option.option_id.content_rich,
                      }}
                    ></label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="step-controller fix">
          <div className="info box">
            <div>
              <span>{activeExamData.exam.questions.length}</span> Sorudan
            </div>
            <div>
              <strong>{activeConfig.last_page - 1}.</strong> soru gösteriliyor.
            </div>
          </div>
          <div className="steps box" style={{ opacity: 0 }}></div>
          {isNextOk ? (
            <>
              {currentPage === totalPages ? (
                <button
                  id="tamamla"
                  className="next-button transform-3"
                  onClick={(e) => {
                    if (
                      containsAudioTag(
                        currentQuestions[0].question_id.content_rich
                      )
                    ) {
                      stopAudio();
                    }
                    document.getElementById("tamamla").disabled = "true";
                    localStorage.removeItem("hotfix");
                    dispatch(toggleLoading(true));
                    dispatch(
                      updateStatus({
                        id: searchParams.get("id"),
                        status: "completed",
                      })
                    )
                      .unwrap()
                      .then((originalPromiseResult) => {
                        setTimeout(() => {
                          navigate("/");
                          dispatch(toggleLoading(false));
                        }, 2000);
                      })
                      .catch((rejectedValueOrSerializedError) => {
                        navigate("/done");
                        dispatch(toggleLoading(false));
                      });
                  }}
                >
                  Tamamla
                  <span>
                    <img src={next} alt="icon" />
                  </span>
                </button>
              ) : (
                <button
                  className="next-button transform-3"
                  disabled={currentPage === totalPages ? true : false}
                  onClick={(e) => {
                    if (
                      containsAudioTag(
                        currentQuestions[0].question_id.content_rich
                      )
                    ) {
                      stopAudio();
                    }
                    handlePageChange(currentPage + 1);
                    soruSuresiRef.current = 0;
                    window.scroll(0, 0);
                  }}
                >
                  İleri
                </button>
              )}
            </>
          ) : (
            <button className="next-button" disabled={true}>
              İleri
            </button>
          )}
        </div>
      </>
    );
  };
  return (
    <>
      {activeExamData.exam.category === "Personality Test" ? (
        <PersonalExamTemplate />
      ) : activeExamData.exam.category === "General Apitute Test" ? (
        <TestTemplate />
      ) : activeExamData.exam.category === "english" ? (
        <EngTestTemplate />
      ) : null}
    </>
  );
};
