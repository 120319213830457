import { motion } from "framer-motion";
import { toggleAlertModal } from "./modalActions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
const dropIn = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: "0",
    opacity: 1,
    transition: { duration: 0.2, type: "Spring", damping: 25, stiffness: 500 },
  },
  exit: { y: "100vh" },
};
export const AlertModal = ({ handleClose, text }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
  }, []);
  const dispatch = useDispatch();
  return (
    <motion.div
      className="backdrop"
      onClick={() => {
        dispatch(toggleAlertModal(false));
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        style={{ height: "fit-content", borderRadius: "8px" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modal_data alert"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div>Lütfen ilk olarak mikrofon ve kamera seçimi yapınız!</div>
        <div
          className="closeButton"
          onClick={(e) => {
            dispatch(toggleAlertModal(false));
          }}
        >
          X
        </div>
      </motion.div>
    </motion.div>
  );
};
