import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { GetJWT } from "../../utils/CookieHelper";
import env from "react-dotenv";
import { AuthInstance } from "../../app/api/authInstances";
function Countdown({ initialTime, onComplete }) {
  const [remainingTime, setRemainingTime] = useState(initialTime);
  const [stamp, setStamp] = useState(initialTime);
  const [searchParams] = useSearchParams();
  const { activeExamData } = useSelector((store) => store.assessments);
  useEffect(() => {
    if (remainingTime <= 0) {
      onComplete();
      return;
    }
    const intervalId = setInterval(() => {
      setRemainingTime((prevRemainingTime) => prevRemainingTime - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [remainingTime, onComplete]);

  const updateStatus = async (data, api) => {
    let config;
    config = {
      duration: data.duration,
      spent_time: data.spent_time,
      left_time: data.left_time,
      device: data.device,
    };
    try {
      const response = await AuthInstance.patch(
        `${env.API_URL}/items/assessment_profile/${data.id}`,
        config,
        {
          headers: {
            Authorization: GetJWT().accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  useEffect(() => {
    if (remainingTime <= 0) {
      return;
    }
    const intervalId = setInterval(() => {
      var sBrowser,
        sUsrAg = navigator.userAgent;
      if (sUsrAg.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
      } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
      } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
        sBrowser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
      } else if (sUsrAg.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
      } else if (sUsrAg.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
      } else if (sUsrAg.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
      } else if (sUsrAg.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
      } else {
        sBrowser = "unknown";
        if (isMobile) {
          sBrowser = "mobile";
        }
      }
      updateStatus({
        id: searchParams.get("id"),
        duration: remainingTime / 60,
        spent_time: activeExamData.exam.duration * 60 - remainingTime,
        left_time: remainingTime,
        device: sBrowser,
      });
      setStamp((prevRemainingTime) => prevRemainingTime - 1);
    }, 2000);
    return () => clearInterval(intervalId);
  }, [stamp, onComplete]);
  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);
  const seconds = remainingTime % 60;
  const displayHours = hours > 0 ? hours.toString().padStart(2, "0") : "";
  const displayMinutes = minutes.toString().padStart(2, "0");
  const displaySeconds = seconds.toString().padStart(2, "0");
  return (
    <>
      {hours === 0 ? null : (
        <>
          <div> {displayHours}</div>
          <div style={{ width: "auto" }}>:</div>
        </>
      )}
      <div> {displayMinutes}</div>
      <div style={{ width: "auto" }}>:</div>
      <div> {displaySeconds}</div>
    </>
  );
}
export default Countdown;
