import { createSlice } from "@reduxjs/toolkit";

const urlChangedSlice = createSlice({
  name: "url",
  initialState: "",
  reducers: {
    urlChanged: (state, action) => {
      return action.payload;
    },
  },
});

export const { urlChanged } = urlChangedSlice.actions;

export default urlChangedSlice.reducer;
