import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/svg/HIRI_MENU_WHITE.svg";
import homeIcon from "../assets/svg/icons/HOME_ICON.svg";
import assesmentsIcon from "../assets/svg/icons/ASSESMENTS_ICON.svg";
import settingsIcon from "../assets/svg/icons/SETTINGS_ICON.svg";
import { Squash } from "hamburger-react";
import { useDispatch } from "react-redux";
import { setMenuRoot, toggleMenu } from "../app/siteActions/actions";
import { useSelector } from "react-redux";
import { logout } from "../app/auth/actions";
const Menu = () => {
  const dispatch = useDispatch();
  const menu = [
    { icon: homeIcon, text: "Ana Sayfa", link: "/" },
    // { icon: assesmentsIcon, text: "İş İlanlarını Keşfet", link: "/explore" },
    // { icon: settingsIcon, text: "Ayarlar", link: "/settings" },
    { icon: settingsIcon, text: "Çıkış Yap" },
  ];
  const { menuOpen, activeMenuRoot } = useSelector((state) => state.site);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    dispatch(setMenuRoot(location.pathname));
  }, [location.pathname]);
  const { profileData } = useSelector((state) => state.auth);
  return (
    <div className={"hiri-left-menu"}>
      {menuOpen && (
        <div
          className="abs-close"
          onClick={(e) => {
            dispatch(toggleMenu());
          }}
        >
          <Squash toggled={menuOpen} />
        </div>
      )}

      <div className="hiri-logo">
        <div className="filter" />
        <div className="filterBorder" />
        <img
          src={logo}
          alt="logo"
          className="responsive-img"
          onClick={(e) => navigate("/")}
        />
      </div>
      <div className="hiri-menu-elements">
        {menu.map((menuEl, index) => (
          <Link key={index} to={menuEl.link}>
            <div
              onClick={(e) => {
                if (menuOpen) dispatch(toggleMenu());

                if (menuEl.text === "Çıkış Yap") {
                  dispatch(logout());
                }
              }}
              className={
                activeMenuRoot === menuEl.link ? "element active" : "element"
              }
            >
              <div className="icon">
                <img src={menuEl.icon} alt="" />
              </div>
              <div className="line">{menuEl.text}</div>
            </div>
          </Link>
        ))}
      </div>
      <div className="hiri-my-account">
        <div className="filter" />
        <div className="profile">
          <div className="pp">
            <img
              src="https://picsum.photos/200/300"
              className="responsive-img"
              alt="pp"
            />
          </div>
          <div className="name">
            {profileData.user !== undefined
              ? profileData.user.first_name + " " + profileData.user.last_name
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
