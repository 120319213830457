import React from "react";
import search from "../assets/svg/icons/SEARCH_ICON.svg";
import { Squash as Squash } from "hamburger-react";
import { useDispatch } from "react-redux";
import { toggleMenu } from "../app/siteActions/actions";
import { useSelector } from "react-redux";
const TopSearchBar = () => {
  const dispatch = useDispatch();

  const { menuOpen } = useSelector((state) => state.site);
  return (
    <div className="top-search-bar">
      <div className="search-area">
        <div className="responsiveMenuToggle">
          <Squash
            toggled={menuOpen}
            toggle={(e) => {
              dispatch(toggleMenu());
            }}
          />
        </div>
        {/* <div className="abs-input-box">
          <input type="text" placeholder="Test adı giriniz." />
          <img src={search} alt="icon" className="responsive-img" />
        </div>
        <div className="notification" /> */}
      </div>
    </div>
  );
};

export default TopSearchBar;
