import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./auth/actions";
import siteReducer from "./siteActions/actions";
import assessmentReducer from "./assessments/actions";
import examReducer from "./examActions/action";
import modalReducer from "../components/modals/modalActions";
import createSagaMiddleware from "redux-saga";
import testReducer from "../testState";
import urlReducer from "../urlSlice";
import rootSaga from "../rootSaga";
const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    auth: authReducer,
    site: siteReducer,
    assessments: assessmentReducer,
    exam: examReducer,
    modals: modalReducer,
    test: testReducer,
    url: urlReducer,
  },

  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(sagaMiddleware),
});
// sagaMiddleware.run(rootSaga);
export default store;
