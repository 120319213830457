import React, { useEffect, useState } from "react";
import orangeTime from "../assets/svg/icons/AVERAGE_TIME_ICON_ORANGE.svg";
import blueTime from "../assets/svg/icons/AVERAGE_TIME_ICON_BLUE.svg";
import orangeDeadline from "../assets/svg/icons/DEADLINE_ICON_ORANGE.svg";
import blueDeadline from "../assets/svg/icons/DEADLINE_ICON_BLUE.svg";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { getAssessmentDetail } from "../app/assessments/actions";
import env from "react-dotenv";
import { GetJWT } from "../utils/CookieHelper";
import axios from "axios";
const AssessmentCard = (props) => {
  const { assessment, noCard } = props;
  require("moment/locale/tr.js");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [assessmentDetail, setAssessmentDetail] = useState(null);
  const [remainTime, setRemainTime] = useState(null);
  const getStatus = async () => {
    try {
      const response = await axios.patch(
        `${env.API_URL}/items/assessment_profile/${assessment.id}`,
        {},
        {
          headers: {
            Authorization: GetJWT().accessToken,
          },
        }
      );
      setRemainTime(response.data.data.duration);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  function convertToMinuteSeconds(decimalMinutes) {
    var minutes = Math.floor(decimalMinutes);
    var seconds = Math.round((decimalMinutes - minutes) * 60);
    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }
  useEffect(() => {
    dispatch(getAssessmentDetail(assessment.assessment_id))
      .unwrap()
      .then((originalPromiseResult) => {
        setAssessmentDetail(originalPromiseResult);
        getStatus();
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }, []);
  const renderEmpty = () => {
    return <div>Henüz atanmış bir testiniz bulunmamaktır.</div>;
  };
  const continueComp = () => {
    return (
      <div className="area">
        <div
          className="button"
          onClick={(e) => {
            window.open(
              `/exam/${assessment.assessment_id}?id=${assessment.id}`
            );
            setTimeout((e) => {
              window.location.reload();
            }, 1000);
          }}
        >
          TESTE DEVAM ET
        </div>
        <div className="timer">
          <div>Kalan Süre</div>
          <div style={{ fontSize: "20px" }}>
            {remainTime === 0 ? (
              <>{assessmentDetail.exam.duration + ":00"}</>
            ) : (
              convertToMinuteSeconds(remainTime)
            )}
          </div>
        </div>
      </div>
    );
  };
  const startComp = () => {
    return (
      <div className="area">
        <div
          className="button blue"
          onClick={(e) => {
            window.open(
              `/exam/${assessment.assessment_id}?id=${assessment.id}`
            );
            setTimeout((e) => {
              window.location.reload();
            }, 1000);
          }}
        >
          TESTE BAŞLA
        </div>
      </div>
    );
  };
  const renderAssessment = () => {
    return (
      <>
        {assessmentDetail !== null && (
          <>
            <div className="a-name">
              <div className="a-img">
                <img
                  src={`${env.API_URL}/assets/${
                    assessmentDetail.company.icon !== null
                      ? assessmentDetail.company.icon.id
                      : null
                  }/alex_turner.png?key=webp-format`}
                  alt="logo"
                  className="responsive-img"
                />
              </div>
              <div className="a-company">{assessmentDetail.company.name}</div>
            </div>
            <div className="divider"></div>
            <div className="a-type">{assessmentDetail.exam.title}</div>
            <div className="divider"></div>
            <div className="a-time">
              <div className="left">
                <div className="icon">
                  <img
                    className="responsive-img"
                    src={assessment.status === "paused" ? orangeTime : blueTime}
                    alt="icon"
                  />
                </div>
                <div className="time-data">
                  <div>Test Süresi</div>
                  <div>{assessmentDetail.exam.duration} dakika</div>
                </div>
              </div>
              <div className="h-divider" />
              <div className="right">
                <div className="icon">
                  <img
                    className="responsive-img"
                    src={
                      assessment.status === "paused"
                        ? orangeDeadline
                        : blueDeadline
                    }
                    alt="icon"
                  />
                </div>
                <div className="time-data">
                  <div>Son Teslim Tarihi</div>
                  <div>
                    {moment(assessmentDetail.expire_date).format("D MMMM")}
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="a-description">
              <div
                dangerouslySetInnerHTML={{
                  __html: assessmentDetail.exam.description_rich,
                }}
              />
            </div>
            <div className="divider"></div>
            <div className="bottom-wrapper">
              {assessment.status === "paused" ? continueComp() : startComp()}
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <>
      <div
        className={
          assessment.status === "paused"
            ? "assessment-card orange"
            : "assessment-card blue"
        }
      >
        {renderAssessment()}
      </div>
    </>
  );
};

export default AssessmentCard;
