import axios from "axios";
import env from "react-dotenv";
import { refreshAuthToken } from "./auth";
import { GetJWT } from "../../utils/CookieHelper";
import { RedirectToLogin } from "../../utils/RedirectHelper";
export const Instance = axios.create({
  baseURL: env.API_URL,
  timeout: 1000,
});
export const AuthInstance = axios.create({
  baseURL: env.API_URL,
  timeout: 1000,
  headers: { Authorization: `Bearer ${GetJWT().accessToken}` },
});

AuthInstance.interceptors.response.use(
  function (response) {
    return response; // succeed response, pass
  },
  async function (error) {
    if (!error.request.responseURL.includes("/auth/refresh")) {
      if (error.response && error.response.status === 403) {
        // get refresh token
        const { refreshToken } = GetJWT();
        return refreshAuthToken(refreshToken, error.response.config);
      }
    } else {
      RedirectToLogin();
    }
    return Promise.reject(error); // 401 degilse, kendin handle et
  }
);
