import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";
import { cookie, GetJWT, setJWT } from "../../utils/CookieHelper";
import { loginUser } from "../api/auth";
import { AuthInstance } from "../api/authInstances";
export const login = createAsyncThunk("login", async function (loginData, api) {
  api.dispatch(toggleLoading(true));

  try {
    const response = await loginUser({
      email: loginData.email,
      password: loginData.password,
      mode: "json",
      otp: "string",
    });
    console.log("loginredux", response);
    api.dispatch(toggleLoading(false));
    return response.data.data;
  } catch (error) {
    api.dispatch(toggleLoading(false));
    console.log(error);
    throw error.response.data.errors[0];
  }
});

export const logout = createAsyncThunk("logout", async (data, api) => {
  api.dispatch(toggleLoading(true));
  await new Promise((resolve) => setTimeout(resolve, 1000));
  try {
    const response = await axios.post(
      `${env.API_URL}/auth/logout`,
      {
        refresh_token: GetJWT().refreshToken,
      },
      {}
    );
    api.dispatch(toggleLoading(false));
    return response.data;
  } catch (error) {
    console.log(error);
    api.dispatch(toggleLoading(false));
    throw error;
  }
});

export const getProfile = createAsyncThunk("getProfile", async (data, api) => {
  api.dispatch(toggleLoading(true));
  try {
    const response = await AuthInstance.get(
      `${env.API_URL}/items/profile?fields=*.*`,
      {
        headers: {
          Authorization: GetJWT().accessToken,
        },
      }
    );
    api.dispatch(toggleLoading(false));
    return response.data.data;
  } catch (error) {
    console.log(error);
    api.dispatch(toggleLoading(false));
    api.dispatch(logout());
    throw error;
  }
});
const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    profileData: [],
  },
  reducers: {
    toggleLoading: (state, action) => {
      state.isLoadingRedux = action.payload;
    },
    testReducer: (state, action) => {
      state.xd = "done";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      setJWT(action.payload);
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      cookie.remove("refreshToken");
      cookie.remove("accessToken");
      window.location.reload();
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profileData = action.payload[0];
    });
  },
});
export const { toggleLoading, testReducer } = authSlice.actions;
export default authSlice.reducer;
