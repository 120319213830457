import { createSlice } from "@reduxjs/toolkit";

const examSlice = createSlice({
  name: "examSlice",
  initialState: {
    activeExamStep: "first",
  },
  reducers: {
    changeExamStep: (state, action) => {
      state.activeExamStep = action.payload;
    },
  },
});
export const { changeExamStep } = examSlice.actions;
export default examSlice.reducer;
